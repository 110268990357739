import { FormikHelpers, useFormik } from 'formik';
import { ReportEdit, Report } from '../report';
import {
  Grid,
  TextField,
  FormControl,
  InputLabel,
  FormHelperText,
  Select,
  MenuItem,
  Box,
  Paper,
  Button,
} from '@mui/material';
import FileContainer from '../../../core/ui/utility/FileContainer';

type ReportProps = {
  onSubmit: (
    value: ReportEdit,
    helpers: FormikHelpers<ReportEdit>
  ) => Promise<boolean>;
  submitting: boolean;
  report: Report;
};

const EditReportForm = ({ onSubmit, submitting, report }: ReportProps) => {
  const handleSubmit = async (
    value: ReportEdit,
    helpers: FormikHelpers<ReportEdit>
  ) => {
    const success = await onSubmit(value, helpers);
    if (success) formik.resetForm();
  };

  const initialValues: ReportEdit = {
    title: report.title,
    report_type: report.report_type,
    attachment: null,
  };

  const formik = useFormik({
    initialValues,
    onSubmit: handleSubmit,
  });

  // console.log('Edit Form', initialValues);

  return (
    <>
      <Box sx={{ flexGrow: 1 }}>
        <form onSubmit={formik.handleSubmit}>
          <Grid container spacing={1}>
            <Grid item xs={12} sm={9}>
              <Paper sx={{ p: 2, pb: 3 }}>
                <Grid
                  container
                  justifyContent="space-between"
                  spacing={1}
                  sx={{ mt: 2 }}
                >
                  <Grid item alignSelf="flex-start" sx={{ flexGrow: 2 }}>
                    <TextField
                      fullWidth
                      name="title"
                      size="small"
                      type="text"
                      variant="outlined"
                      label="Title"
                      placeholder="Title"
                      value={formik.values.title}
                      onChange={formik.handleChange}
                      error={Boolean(formik.errors.title)}
                      helperText={formik.errors.title}
                    />
                  </Grid>

                  <Grid item alignSelf="flex-start" sx={{ flexGrow: 1 }}>
                    <FormControl
                      sx={{ minWidth: 120 }}
                      error={Boolean(formik.errors.report_type)}
                    >
                      <InputLabel>Report Type</InputLabel>
                      <Select
                        value={formik.values.report_type}
                        size="small"
                        label="Report Type"
                        onChange={formik.handleChange}
                        name="report_type"
                      >
                        <MenuItem value="audit">Audit Report</MenuItem>
                        <MenuItem value="annual">Annual Report</MenuItem>
                      </Select>
                      {Boolean(formik.errors.report_type) && (
                        <FormHelperText>
                          {formik.errors.report_type}
                        </FormHelperText>
                      )}
                    </FormControl>
                  </Grid>
                </Grid>
                <Grid
                  container
                  justifyContent="space-between"
                  spacing={1}
                  sx={{ mt: 2 }}
                >
                  <Grid item alignSelf="flex-start" sx={{ flexGrow: 1 }}>
                    <Grid item xs={12}>
                      Attachment
                    </Grid>
                    <FileContainer media={report.media[0]} />
                    <Box>
                      <input
                        type="file"
                        onChange={({ target: { files } }) => {
                          formik.setFieldValue(
                            'attachment',
                            files && files.length ? files[0] : null
                          );
                        }}
                        name="attachment"
                      />
                    </Box>
                    {formik.touched.attachment && formik.errors.attachment && (
                      <FormHelperText error>
                        {formik.errors.attachment}
                      </FormHelperText>
                    )}
                  </Grid>
                </Grid>
              </Paper>
            </Grid>

            <Grid item xs={12} sm={3}>
              <Paper sx={{ p: 2 }}>
                <Grid container>
                  <Button
                    type="submit"
                    variant="contained"
                    disabled={submitting}
                    size="small"
                  >
                    {submitting ? 'Saving' : 'Save'}
                  </Button>
                </Grid>
              </Paper>
            </Grid>
          </Grid>
        </form>
      </Box>
    </>
  );
};

export default EditReportForm;
