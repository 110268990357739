import { FormikHelpers, useFormik } from 'formik';
import { JobEdit, Job } from '../job';
import { Grid, TextField, Box, Paper } from '@mui/material';
import FileContainer from '../../../core/ui/utility/FileContainer';

type JobProps = {
  onSubmit: (
    value: JobEdit,
    helpers: FormikHelpers<JobEdit>
  ) => Promise<boolean>;
  submitting: boolean;
  job: Job;
};

const EditJobForm = ({ onSubmit, submitting, job }: JobProps) => {
  const handleSubmit = async (
    value: JobEdit,
    helpers: FormikHelpers<JobEdit>
  ) => {
    const success = await onSubmit(value, helpers);
    if (success) formik.resetForm();
  };

  const initialValues: JobEdit = {
    first_name: job.first_name,
    last_name: job.last_name,
    phone: job.phone,
    email: job.email,
    cv: null,
  };

  const formik = useFormik({
    initialValues,
    onSubmit: handleSubmit,
  });

  // console.log('Edit Form', initialValues);

  return (
    <>
      <Box sx={{ flexGrow: 1 }}>
        <form onSubmit={formik.handleSubmit}>
          <Grid container spacing={1}>
            <Grid item xs={12} sm={9}>
              <Paper sx={{ p: 2, pb: 3 }}>
                <Grid
                  container
                  justifyContent="space-between"
                  spacing={1}
                  sx={{ mt: 2 }}
                >
                  <Grid item alignSelf="flex-start" sx={{ flexGrow: 1 }}>
                    <TextField
                      fullWidth
                      name="first_name"
                      size="small"
                      type="text"
                      variant="outlined"
                      label="First name"
                      placeholder="First name"
                      value={formik.values.first_name}
                      onChange={formik.handleChange}
                      error={Boolean(formik.errors.first_name)}
                      helperText={formik.errors.first_name}
                    />
                  </Grid>

                  <Grid item alignSelf="flex-start" sx={{ flexGrow: 1 }}>
                    <TextField
                      fullWidth
                      name="last_name"
                      size="small"
                      type="text"
                      variant="outlined"
                      label="Last name"
                      placeholder="Last name"
                      value={formik.values.last_name}
                      onChange={formik.handleChange}
                      error={Boolean(formik.errors.last_name)}
                      helperText={formik.errors.last_name}
                    />
                  </Grid>
                </Grid>
                <Grid
                  container
                  justifyContent="space-between"
                  spacing={1}
                  sx={{ mt: 2 }}
                >
                  <Grid item alignSelf="flex-start" sx={{ flexGrow: 1 }}>
                    <TextField
                      fullWidth
                      name="phone"
                      size="small"
                      type="text"
                      variant="outlined"
                      label="Phone"
                      placeholder="Phone"
                      value={formik.values.phone}
                      onChange={formik.handleChange}
                      error={Boolean(formik.errors.phone)}
                      helperText={formik.errors.phone}
                    />
                  </Grid>

                  <Grid item alignSelf="flex-start" sx={{ flexGrow: 1 }}>
                    <TextField
                      fullWidth
                      name="email"
                      size="small"
                      type="text"
                      variant="outlined"
                      label="Email"
                      placeholder="Email"
                      value={formik.values.email}
                      onChange={formik.handleChange}
                      error={Boolean(formik.errors.email)}
                      helperText={formik.errors.email}
                    />
                  </Grid>
                </Grid>
                <Grid
                  container
                  justifyContent="space-between"
                  spacing={1}
                  sx={{ mt: 2 }}
                >
                  <Grid item alignSelf="flex-start" sx={{ flexGrow: 1 }}>
                    <Grid item xs={12}>
                      CV
                    </Grid>
                    <FileContainer media={job.media[0]} />
                  </Grid>

                  <Grid item sx={{ flexGrow: 2 }}></Grid>
                </Grid>
              </Paper>
            </Grid>

            <Grid item xs={12} sm={3}></Grid>
          </Grid>
        </form>
      </Box>
    </>
  );
};

export default EditJobForm;
