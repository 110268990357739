import { FormikHelpers, useFormik } from 'formik';
import { HomeInfoCreate, HomeInfo } from '../homeInfo';
import { Grid, TextField, Paper, Box, Button } from '@mui/material';

type HomeInfoProps = {
  onSubmit: (
    value: HomeInfoCreate,
    helpers: FormikHelpers<HomeInfoCreate>
  ) => Promise<boolean>;
  submitting: boolean;
  homeInfo: HomeInfo | null;
};

// TODO: Fix home form
const CreateHomeInfoForm = ({
  onSubmit,
  submitting,
  homeInfo,
}: HomeInfoProps) => {
  const handleSubmit = async (
    value: HomeInfoCreate,
    helpers: FormikHelpers<HomeInfoCreate>
  ) => {
    const success = await onSubmit(value, helpers);
    if (success) formik.resetForm();
  };

  // console.log({ homeInfo }, homeInfo === null);

  const initialValues: HomeInfoCreate =
    homeInfo === null
      ? {
          women: 0,
          women_programs: 0,
          girls: 0,
          girls_programs: 0,
          money_raised: '',
          money_duration: '',
        }
      : {
          women: homeInfo.women,
          women_programs: homeInfo.women_programs,
          girls: homeInfo.girls,
          girls_programs: homeInfo.girls_programs,
          money_raised: homeInfo.money_raised,
          money_duration: homeInfo.money_duration,
        };

  const formik = useFormik({
    initialValues,
    onSubmit: handleSubmit,
  });

  // console.log('Edit Form', initialValues);

  return (
    <>
      <Box sx={{ flexGrow: 1 }}>
        <form onSubmit={formik.handleSubmit}>
          <Grid container spacing={1}>
            <Grid item xs={12} sm={9}>
              <Paper sx={{ p: 2, pb: 3 }}>
                <Grid
                  container
                  justifyContent="space-between"
                  spacing={1}
                  sx={{ mt: 2 }}
                >
                  <Grid item alignSelf="flex-start" sx={{ flexGrow: 1 }}>
                    <TextField
                      fullWidth
                      name="women"
                      size="small"
                      type="number"
                      variant="outlined"
                      label="Women Impacted"
                      placeholder="Women Impacted"
                      value={formik.values.women}
                      onChange={formik.handleChange}
                      error={Boolean(formik.errors.women)}
                      helperText={formik.errors.women}
                    />
                  </Grid>

                  <Grid item alignSelf="flex-start" sx={{ flexGrow: 1 }}>
                    <TextField
                      fullWidth
                      name="women_programs"
                      size="small"
                      type="number"
                      variant="outlined"
                      label="Women Programs"
                      placeholder="Women Programs"
                      value={formik.values.women_programs}
                      onChange={formik.handleChange}
                      error={Boolean(formik.errors.women_programs)}
                      helperText={formik.errors.women_programs}
                    />
                  </Grid>

                  <Grid item sx={{ flexGrow: 1 }}></Grid>
                </Grid>
                <Grid
                  container
                  justifyContent="space-between"
                  spacing={1}
                  sx={{ mt: 2 }}
                >
                  <Grid item alignSelf="flex-start" sx={{ flexGrow: 1 }}>
                    <TextField
                      fullWidth
                      name="girls"
                      size="small"
                      type="number"
                      variant="outlined"
                      label="Girls Impacted"
                      placeholder="Girls Impacted"
                      value={formik.values.girls}
                      onChange={formik.handleChange}
                      error={Boolean(formik.errors.girls)}
                      helperText={formik.errors.girls}
                    />
                  </Grid>

                  <Grid item alignSelf="flex-start" sx={{ flexGrow: 1 }}>
                    <TextField
                      fullWidth
                      name="girls_programs"
                      size="small"
                      type="number"
                      variant="outlined"
                      label="Girls Programs"
                      placeholder="Girls Programs"
                      value={formik.values.girls_programs}
                      onChange={formik.handleChange}
                      error={Boolean(formik.errors.girls_programs)}
                      helperText={formik.errors.girls_programs}
                    />
                  </Grid>

                  <Grid item sx={{ flexGrow: 1 }}></Grid>
                </Grid>
                <Grid
                  container
                  justifyContent="space-between"
                  spacing={1}
                  sx={{ mt: 2 }}
                >
                  <Grid item alignSelf="flex-start" sx={{ flexGrow: 1 }}>
                    <TextField
                      fullWidth
                      name="money_raised"
                      size="small"
                      type="text"
                      variant="outlined"
                      label="Money Raised"
                      placeholder="Money Raised"
                      value={formik.values.money_raised}
                      onChange={formik.handleChange}
                      error={Boolean(formik.errors.money_raised)}
                      helperText={formik.errors.money_raised}
                    />
                  </Grid>

                  <Grid item alignSelf="flex-start" sx={{ flexGrow: 1 }}>
                    <TextField
                      fullWidth
                      name="money_duration"
                      size="small"
                      type="text"
                      variant="outlined"
                      label="Money raised duration"
                      placeholder="Money raised duration"
                      value={formik.values.money_duration}
                      onChange={formik.handleChange}
                      error={Boolean(formik.errors.money_duration)}
                      helperText={formik.errors.money_duration}
                    />
                  </Grid>

                  <Grid item sx={{ flexGrow: 1 }}></Grid>
                </Grid>
              </Paper>
            </Grid>

            <Grid item xs={12} sm={3}>
              <Paper sx={{ p: 2 }}>
                <Grid container>
                  <Button
                    type="submit"
                    variant="contained"
                    disabled={submitting}
                    size="small"
                  >
                    {submitting ? 'Saving' : 'Save'}
                  </Button>
                </Grid>
              </Paper>
            </Grid>
          </Grid>
        </form>
      </Box>
    </>
  );
};

export default CreateHomeInfoForm;
