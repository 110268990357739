import { Grid, Typography } from '@mui/material';
import { FormikHelpers } from 'formik';
import Layout from '../../../core/ui/layout/Layout';
import { useSendApiData } from '../../../core/hooks/useSendApiData';
import { toastError, toastMessage } from '../../../core/utils/ui/alert';
import { parseValidationErrors } from '../../../core/utils/validation';
import { HomeInfoCreate, HomeInfo } from '../homeInfo';
import CreateHomeInfoForm from '../components/CreateHomeInfoForm';
import ReturnButton from '../../../core/ui/utility/ReturnButton';
import { useState, useEffect } from 'react';
import useFetchApiData from '../../../core/hooks/useFetchApiData';
import Loader from '../../../core/ui/utility/Loader';

const CreateHomeInfoContainer = () => {
  const { callApi, loading: submitting } = useSendApiData();
  const { fetchData, loading } = useFetchApiData();
  const [homeInfo, setHomeInfo] = useState<HomeInfo | null>(null);

  const fetchHomeInfo = () =>
    fetchData(`home`, {
      onSuccess: ({ data }) => {
        // console.log({ data });
        setHomeInfo(data);
      },
    });

  useEffect(() => {
    fetchHomeInfo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmit = async (
    values: HomeInfoCreate,
    { setFieldError }: FormikHelpers<HomeInfoCreate>
  ) => {
    let success = false;

    const formData: any = { ...values };
    const dateFields: string[] = [];
    dateFields.forEach((field) => {
      if (formData[field]) {
        formData[field] = formData[field]
          .toISOString()
          .substring(0, 10) as unknown as Date;
      }
    });

    await callApi({
      endpoint: 'auth-ea35a/home',
      data: formData,
      headers: {
        'Content-Type': 'application/json',
      },
      onValidationError: (err) => parseValidationErrors(err, setFieldError),
      onError: toastError,
      onSuccess: async (_) => {
        await fetchHomeInfo();
        toastMessage('HomeInfo Created');
        success = true;
      },
    });

    return success;
  };

  return (
    <Layout renderLeftToolbar={() => <ReturnButton to="/home" />}>
      <Loader loading={loading}>
        <Grid sx={{ p: 2 }}>
          <Grid container sx={{ mb: 2, px: 1 }}>
            <Typography variant="h5">
              {homeInfo ? 'Edit' : 'Add'} Home Info
            </Typography>
          </Grid>
          <CreateHomeInfoForm
            homeInfo={homeInfo}
            onSubmit={handleSubmit}
            submitting={submitting}
          />
        </Grid>
      </Loader>
    </Layout>
  );
};

export default CreateHomeInfoContainer;
