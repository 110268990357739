import { Grid, Typography } from '@mui/material';
import { FormikHelpers } from 'formik';
import { useParams } from 'react-router-dom';
import { useState, useEffect } from 'react';
import Layout from '../../../core/ui/layout/Layout';
import { useSendApiData } from '../../../core/hooks/useSendApiData';
import { toastError, toastMessage } from '../../../core/utils/ui/alert';
import { parseValidationErrors } from '../../../core/utils/validation';
import useFetchApiData from '../../../core/hooks/useFetchApiData';
import { BoardEdit, Board } from '../board';
import EditBoardForm from '../components/EditBoardForm';
import ReturnButton from '../../../core/ui/utility/ReturnButton';
import Loader from '../../../core/ui/utility/Loader';

const EditBoardContainer = () => {
  const { id } = useParams();
  const { callApi, loading: submitting } = useSendApiData();
  const { fetchData, loading } = useFetchApiData();
  const [board, setBoard] = useState<Board | null>(null);

  const fetchBoard = () =>
    fetchData(`boards/${id}`, {
      onSuccess: (data: Board) => {
        setBoard(data);
      },
    });

  useEffect(() => {
    fetchBoard();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmit = async (
    values: BoardEdit,
    { setFieldError }: FormikHelpers<BoardEdit>
  ) => {
    let success = false;

    // console.log(values);
    const formData: any = new FormData();
    Object.entries(values).forEach(([key, val]) => {
      if (val !== null) {
        formData.append(key, val as Blob);
      }
    });
    // console.log(formData);

    await callApi({
      endpoint: `auth-ea35a/boards/${id}?_method=PATCH`,
      method: 'post',
      data: formData,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      onValidationError: (err) => parseValidationErrors(err, setFieldError),
      onError: toastError,
      onSuccess: async (_) => {
        await fetchBoard();
        toastMessage('Board Edited');
        success = true;
      },
    });

    return success;
  };

  return (
    <Layout renderLeftToolbar={() => <ReturnButton to="/boards" />}>
      <Loader loading={loading || !board}>
        <Grid sx={{ p: 2 }}>
          <Grid container sx={{ mb: 1, px: 1 }}>
            <Typography variant="h5">Edit Board</Typography>
          </Grid>
          <EditBoardForm
            board={board!}
            onSubmit={handleSubmit}
            submitting={submitting}
          />
        </Grid>
      </Loader>
    </Layout>
  );
};

export default EditBoardContainer;
