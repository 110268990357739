import { Box } from '@mui/material';
import { useState } from 'react';
import useFetchApiData from '../../../core/hooks/useFetchApiData';
import Layout from '../../../core/ui/layout/Layout';
import TableContainer from '../../../core/ui/table/TableContainer';
import { TableHeader } from '../../../core/ui/table/tableTypes';
import { Paginated } from '../../../core/utils/types';
import { parseQuery } from '../../../core/utils/utility';
import { Partner } from '../partner';
import { useSendApiData } from '../../../core/hooks/useSendApiData';
import { toastError, toastMessage } from '../../../core/utils/ui/alert';

const tableHeaders: TableHeader[] = [
  { field: 'company_name', label: 'Company Name', align: 'left' },
  { field: 'email', label: 'Email', align: 'left' },
  { field: 'phone', label: 'Phone', align: 'left' },
];
const PartnerList = () => {
  const [data, setData] = useState<Record<string, any>[]>([]);
  const { fetchData } = useFetchApiData();
  const { callApi } = useSendApiData();

  const handleFetchData = async (query: any, cb: (c: number) => void) => {
    await fetchData(`auth-ea35a/partners${parseQuery(query)}`, {
      onSuccess: ({ total, data: apiData }: Paginated<Partner>) => {
        setData(apiData);
        cb(total);
      },
    });
  };

  const handleDelete = async (id: number | string, showMessage = true) => {
    let success = false;

    await callApi({
      endpoint: `auth-ea35a/partners/${id}`,
      data: {},
      method: 'delete',
      onSuccess: async () => {
        if (showMessage) toastMessage('Partner Removed');
        success = true;
      },
      onError: () => {
        if (showMessage)
          toastError('Something went wrong while deleting. Try again');
      },
    });

    return success;
  };

  return (
    <>
      <Layout>
        <Box sx={{ p: 2 }}>
          <TableContainer
            modelToken="partner"
            tableHeaders={tableHeaders}
            data={data}
            modelLabel="Partners"
            addRoute="/partners/create"
            editRoutePrefix="/partners/edit"
            viewRoutePrefix="/partners/edit"
            actions={{
              onFetchData: handleFetchData,
              onDelete: handleDelete,
            }}
            fieldMap={{
              company_name: 'string',
              email: 'string',
              phone: 'string',
            }}
            settings={{
              canCreate: false,
              canEdit: false,
              canDelete: true,
              canViewItem: true,
            }}
          />
        </Box>
      </Layout>
    </>
  );
};

export default PartnerList;
