import { FormikHelpers, useFormik } from 'formik';
import { VacancyEdit, Vacancy } from '../vacancy';
import {
  Grid,
  TextField,
  FormHelperText,
  FormControl,
  FormControlLabel,
  Checkbox,
  Paper,
  Box,
  Button,
} from '@mui/material';
import DatePicker from 'react-datepicker';
import JobList from './JobList';

type VacancyProps = {
  onSubmit: (
    value: VacancyEdit,
    helpers: FormikHelpers<VacancyEdit>
  ) => Promise<boolean>;
  submitting: boolean;
  vacancy: Vacancy;
};

const EditVacancyForm = ({ onSubmit, submitting, vacancy }: VacancyProps) => {
  const handleSubmit = async (
    value: VacancyEdit,
    helpers: FormikHelpers<VacancyEdit>
  ) => {
    const success = await onSubmit(value, helpers);
    if (success) formik.resetForm();
  };

  const initialValues: VacancyEdit = {
    title: vacancy.title,
    description: vacancy.description,
    experience_years: vacancy.experience_years,
    positions: vacancy.positions,
    deadline: new Date(vacancy.deadline),
    published: !!vacancy.published,
  };

  const formik = useFormik({
    initialValues,
    onSubmit: handleSubmit,
  });

  // console.log('Edit Form', initialValues);

  return (
    <>
      <Box sx={{ flexGrow: 1 }}>
        <form onSubmit={formik.handleSubmit}>
          <Grid container spacing={1}>
            <Grid item xs={12} sm={9}>
              <Paper sx={{ p: 2, pb: 3 }}>
                <Grid
                  container
                  justifyContent="space-between"
                  spacing={1}
                  sx={{ mt: 2 }}
                >
                  <Grid item alignSelf="flex-start" sx={{ flexGrow: 1 }}>
                    <TextField
                      fullWidth
                      name="title"
                      size="small"
                      type="text"
                      variant="outlined"
                      label="Title"
                      placeholder="Title"
                      value={formik.values.title}
                      onChange={formik.handleChange}
                      error={Boolean(formik.errors.title)}
                      helperText={formik.errors.title}
                    />
                  </Grid>

                  <Grid item sx={{ flexGrow: 1 }}></Grid>
                </Grid>
                <Grid
                  container
                  justifyContent="space-between"
                  spacing={1}
                  sx={{ mt: 2 }}
                >
                  <Grid item alignSelf="flex-start" sx={{ flexGrow: 1 }}>
                    <TextField
                      fullWidth
                      name="description"
                      size="small"
                      type="text"
                      variant="outlined"
                      label="Description"
                      placeholder="Description"
                      value={formik.values.description}
                      onChange={formik.handleChange}
                      error={Boolean(formik.errors.description)}
                      helperText={formik.errors.description}
                      multiline
                      rows={4}
                    />
                  </Grid>
                </Grid>
                <Grid
                  container
                  justifyContent="space-between"
                  spacing={1}
                  sx={{ mt: 2 }}
                >
                  <Grid item alignSelf="flex-start" sx={{ flexGrow: 1 }}>
                    <TextField
                      fullWidth
                      name="experience_years"
                      size="small"
                      type="number"
                      variant="outlined"
                      label="Experience Years"
                      placeholder="Experience Years"
                      value={formik.values.experience_years}
                      onChange={formik.handleChange}
                      error={Boolean(formik.errors.experience_years)}
                      helperText={formik.errors.experience_years}
                    />
                  </Grid>

                  <Grid item alignSelf="flex-start" sx={{ flexGrow: 1 }}>
                    <TextField
                      fullWidth
                      name="positions"
                      size="small"
                      type="number"
                      variant="outlined"
                      label="Positions"
                      placeholder="Positions"
                      value={formik.values.positions}
                      onChange={formik.handleChange}
                      error={Boolean(formik.errors.positions)}
                      helperText={formik.errors.positions}
                    />
                  </Grid>

                  <Grid item sx={{ flexGrow: 1 }} alignItems="flex-end">
                    <Grid item>Deadline</Grid>
                    <Grid item alignItems="flex-end">
                      <DatePicker
                        selected={formik.values.deadline || new Date()}
                        onChange={(e) => {
                          formik.setFieldValue('deadline', e);
                        }}
                        dateFormat="yyyy/MM/dd"
                      />
                    </Grid>
                    <Grid item xs={12}>
                      {formik.touched.deadline && formik.errors.deadline && (
                        <FormHelperText error>
                          {formik.errors.deadline}
                        </FormHelperText>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
                <Grid
                  container
                  justifyContent="space-between"
                  spacing={1}
                  sx={{ mt: 2 }}
                >
                  <Grid item alignSelf="flex-start" sx={{ flexGrow: 1 }}>
                    <FormControl error>
                      <FormControlLabel
                        control={
                          <Checkbox
                            name="published"
                            checked={formik.values.published}
                            onChange={formik.handleChange}
                          />
                        }
                        label="Published"
                      />
                      {Boolean(formik.errors.published) && (
                        <FormHelperText>
                          {formik.errors.published}
                        </FormHelperText>
                      )}
                    </FormControl>
                  </Grid>

                  <Grid item sx={{ flexGrow: 4 }}></Grid>
                </Grid>
              </Paper>
            </Grid>

            <Grid item xs={12} sm={3}>
              <Paper sx={{ p: 2 }}>
                <Grid container>
                  <Button
                    type="submit"
                    variant="contained"
                    disabled={submitting}
                    size="small"
                  >
                    {submitting ? 'Saving' : 'Save'}
                  </Button>
                </Grid>
              </Paper>
            </Grid>
          </Grid>
        </form>

        <JobList vacancyId={vacancy.id} />
      </Box>
    </>
  );
};

export default EditVacancyForm;
