import { BrowserRouter, Routes, Route } from 'react-router-dom';
import PrivateRoute from '../../features/auth/containers/PrivateRoute';
import LoginPage from '../../features/auth/pages/LoginPage';
import ProfilePage from '../../features/auth/pages/ProfilePage';
import HomePage from '../../features/HomePage';
import ListVacancyPage from '../../features/vacancy/pages/ListVacancyPage';
import CreateVacancyPage from '../../features/vacancy/pages/CreateVacancyPage';
import EditVacancyPage from '../../features/vacancy/pages/EditVacancyPage';
import ListNewsletterPage from '../../features/newsletter/pages/ListNewsletterPage';
import CreateNewsletterPage from '../../features/newsletter/pages/CreateNewsletterPage';
import EditNewsletterPage from '../../features/newsletter/pages/EditNewsletterPage';
import ListSuccessStoryPage from '../../features/successStory/pages/ListSuccessStoryPage';
import CreateSuccessStoryPage from '../../features/successStory/pages/CreateSuccessStoryPage';
import EditSuccessStoryPage from '../../features/successStory/pages/EditSuccessStoryPage';
import ListSubscriptionPage from '../../features/subscription/pages/ListSubscriptionPage';
import ListImpactPage from '../../features/impact/pages/ListImpactPage';
import CreateImpactPage from '../../features/impact/pages/CreateImpactPage';
import EditImpactPage from '../../features/impact/pages/EditImpactPage';
import ListReportPage from '../../features/report/pages/ListReportPage';
import CreateReportPage from '../../features/report/pages/CreateReportPage';
import EditReportPage from '../../features/report/pages/EditReportPage';
import ListContactPage from '../../features/contact/pages/ListContactPage';
import ListPartnerPage from '../../features/partner/pages/ListPartnerPage';
import ListBoardPage from '../../features/board/pages/ListBoardPage';
import CreateBoardPage from '../../features/board/pages/CreateBoardPage';
import EditBoardPage from '../../features/board/pages/EditBoardPage';
import ListTeamPage from '../../features/team/pages/ListTeamPage';
import CreateTeamPage from '../../features/team/pages/CreateTeamPage';
import EditTeamPage from '../../features/team/pages/EditTeamPage';
import CreateHomeInfoPage from '../../features/homeInfo/pages/CreateHomeInfoPage';
import EditContactPage from '../../features/contact/pages/ViewContactPage';
import EditPartnerPage from '../../features/partner/pages/EditPartnerPage';
import EditJobPage from '../../features/vacancy/pages/EditJobPage';
import ListPublicationPage from '../../features/publication/pages/ListPublicationPage';
import CreatePublicationPage from '../../features/publication/pages/CreatePublicationPage';
import EditPublicationPage from '../../features/publication/pages/EditPublicationPage';

const RouteManager = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/login" element={<LoginPage />} />
        <Route
          path="/"
          element={
            <PrivateRoute>
              <HomePage />
            </PrivateRoute>
          }
        />
        <Route
          path="/profile"
          element={
            <PrivateRoute>
              <ProfilePage />
            </PrivateRoute>
          }
        />

        {/* Vacancy Routes */}
        <Route
          path="/vacancies"
          element={
            <PrivateRoute>
              <ListVacancyPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/vacancies/create"
          element={
            <PrivateRoute>
              <CreateVacancyPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/vacancies/edit/:id"
          element={
            <PrivateRoute>
              <EditVacancyPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/vacancies/:vacancyId/jobs/:id"
          element={
            <PrivateRoute>
              <EditJobPage />
            </PrivateRoute>
          }
        />

        {/* Newsletter routes */}
        <Route
          path="/newsletters"
          element={
            <PrivateRoute>
              <ListNewsletterPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/newsletters/create"
          element={
            <PrivateRoute>
              <CreateNewsletterPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/newsletters/edit/:id"
          element={
            <PrivateRoute>
              <EditNewsletterPage />
            </PrivateRoute>
          }
        />

        {/* SuccessStory routes */}
        <Route
          path="/success-stories"
          element={
            <PrivateRoute>
              <ListSuccessStoryPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/success-stories/create"
          element={
            <PrivateRoute>
              <CreateSuccessStoryPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/success-stories/edit/:id"
          element={
            <PrivateRoute>
              <EditSuccessStoryPage />
            </PrivateRoute>
          }
        />

        {/* Subscription routes  */}
        <Route
          path="/subscriptions"
          element={
            <PrivateRoute>
              <ListSubscriptionPage />
            </PrivateRoute>
          }
        />

        {/* Impact Routes  */}
        <Route
          path="/impacts"
          element={
            <PrivateRoute>
              <ListImpactPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/impacts/create"
          element={
            <PrivateRoute>
              <CreateImpactPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/impacts/edit/:id"
          element={
            <PrivateRoute>
              <EditImpactPage />
            </PrivateRoute>
          }
        />

        {/* Report Routes  */}
        <Route
          path="/reports"
          element={
            <PrivateRoute>
              <ListReportPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/reports/create"
          element={
            <PrivateRoute>
              <CreateReportPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/reports/edit/:id"
          element={
            <PrivateRoute>
              <EditReportPage />
            </PrivateRoute>
          }
        />

        {/* Contact Routes  */}
        <Route
          path="/contacts"
          element={
            <PrivateRoute>
              <ListContactPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/contacts/view/:id"
          element={
            <PrivateRoute>
              <EditContactPage />
            </PrivateRoute>
          }
        />

        {/* Partner Routes  */}
        <Route
          path="/partners"
          element={
            <PrivateRoute>
              <ListPartnerPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/partners/edit/:id"
          element={
            <PrivateRoute>
              <EditPartnerPage />
            </PrivateRoute>
          }
        />

        {/* Board Routes  */}
        <Route
          path="/boards"
          element={
            <PrivateRoute>
              <ListBoardPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/boards/create"
          element={
            <PrivateRoute>
              <CreateBoardPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/boards/edit/:id"
          element={
            <PrivateRoute>
              <EditBoardPage />
            </PrivateRoute>
          }
        />

        {/* Team Routes  */}
        <Route
          path="/teams"
          element={
            <PrivateRoute>
              <ListTeamPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/teams/create"
          element={
            <PrivateRoute>
              <CreateTeamPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/teams/edit/:id"
          element={
            <PrivateRoute>
              <EditTeamPage />
            </PrivateRoute>
          }
        />

        {/* Home Info Routes  */}
        <Route
          path="/home-info"
          element={
            <PrivateRoute>
              <CreateHomeInfoPage />
            </PrivateRoute>
          }
        />

        {/* PUblication Routes  */}
        <Route
          path="/publications"
          element={
            <PrivateRoute>
              <ListPublicationPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/publications/create"
          element={
            <PrivateRoute>
              <CreatePublicationPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/publications/edit/:id"
          element={
            <PrivateRoute>
              <EditPublicationPage />
            </PrivateRoute>
          }
        />

        <Route path="*" element={<>Not Found page</>} />
      </Routes>
    </BrowserRouter>
  );
};

export default RouteManager;
