import { Grid, Typography } from '@mui/material';
import { FormikHelpers } from 'formik';
import { useParams } from 'react-router-dom';
import { useState, useEffect } from 'react';
import Layout from '../../../core/ui/layout/Layout';
import { useSendApiData } from '../../../core/hooks/useSendApiData';
import { toastError, toastMessage } from '../../../core/utils/ui/alert';
import { parseValidationErrors } from '../../../core/utils/validation';
import useFetchApiData from '../../../core/hooks/useFetchApiData';
import { PartnerEdit, Partner } from '../partner';
import EditPartnerForm from '../components/EditPartnerForm';
import ReturnButton from '../../../core/ui/utility/ReturnButton';
import Loader from '../../../core/ui/utility/Loader';

const EditPartnerContainer = () => {
  const { id } = useParams();
  const { callApi, loading: submitting } = useSendApiData();
  const { fetchData, loading } = useFetchApiData();
  const [partner, setPartner] = useState<Partner | null>(null);

  const fetchPartner = () =>
    fetchData(`auth-ea35a/partners/${id}`, {
      onSuccess: (data: Partner) => {
        setPartner(data);
      },
    });

  useEffect(() => {
    fetchPartner();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmit = async (
    values: PartnerEdit,
    { setFieldError }: FormikHelpers<PartnerEdit>
  ) => {
    let success = false;

    const formData: any = new FormData();
    Object.entries(values).forEach(([key, val]) => {
      if (val !== null) {
        formData.append(key, val as Blob);
      }
    });

    await callApi({
      endpoint: `auth-ea35a/partners/${id}?_method=PATCH`,
      method: 'post',
      data: formData,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      onValidationError: (err) => parseValidationErrors(err, setFieldError),
      onError: toastError,
      onSuccess: async (_) => {
        await fetchPartner();
        toastMessage('Partner Edited');
        success = true;
      },
    });

    return success;
  };

  return (
    <Layout renderLeftToolbar={() => <ReturnButton to="/partners" />}>
      <Loader loading={loading || !partner}>
        <Grid sx={{ p: 2 }}>
          <Grid container sx={{ mb: 1, px: 1 }}>
            <Typography variant="h5">View Partner</Typography>
          </Grid>
          <EditPartnerForm
            partner={partner!}
            onSubmit={handleSubmit}
            submitting={submitting}
          />
        </Grid>
      </Loader>
    </Layout>
  );
};

export default EditPartnerContainer;
