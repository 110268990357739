import { FormikHelpers, useFormik } from 'formik';
import { SuccessStoryCreate } from '../successStory';
import {
  Grid,
  TextField,
  FormHelperText,
  Box,
  Paper,
  Button,
} from '@mui/material';
import ReactQuill from 'react-quill';
import { richTextModules } from '../../../core/utils/utility';
import { useNavigate } from 'react-router-dom';

type SuccessStoryProps = {
  onSubmit: (
    value: SuccessStoryCreate,
    helpers: FormikHelpers<SuccessStoryCreate>
  ) => Promise<boolean>;
  submitting: boolean;
};

const CreateSuccessStoryForm = ({
  onSubmit,
  submitting,
}: SuccessStoryProps) => {
  const navigate = useNavigate();

  const handleSubmit = async (
    value: SuccessStoryCreate,
    helpers: FormikHelpers<SuccessStoryCreate>
  ) => {
    const success = await onSubmit(value, helpers);
    if (success) {
      navigate('/', { replace: true });
      navigate('/success-stories/create', { replace: true });
    }
  };

  const initialValues: SuccessStoryCreate = {
    title: '',
    description: '',
    content: '',
    cover: null,
  };

  const formik = useFormik({
    initialValues,
    onSubmit: handleSubmit,
  });

  // console.log('Edit Form', initialValues);

  return (
    <>
      <Box sx={{ flexGrow: 1 }}>
        <form onSubmit={formik.handleSubmit}>
          <Grid container spacing={1}>
            <Grid item xs={12} sm={9}>
              <Paper sx={{ p: 2, pb: 3 }}>
                <Grid
                  container
                  justifyContent="space-between"
                  spacing={1}
                  sx={{ mt: 2 }}
                >
                  <Grid item alignSelf="flex-start" sx={{ flexGrow: 1 }}>
                    <TextField
                      fullWidth
                      name="title"
                      size="small"
                      type="text"
                      variant="outlined"
                      label="Title"
                      placeholder="Title"
                      value={formik.values.title}
                      onChange={formik.handleChange}
                      error={Boolean(formik.errors.title)}
                      helperText={formik.errors.title}
                    />
                  </Grid>

                  <Grid item sx={{ flexGrow: 2 }}></Grid>
                </Grid>
                <Grid
                  container
                  justifyContent="space-between"
                  spacing={1}
                  sx={{ mt: 2 }}
                >
                  <Grid item alignSelf="flex-start" sx={{ flexGrow: 1 }}>
                    <TextField
                      fullWidth
                      name="description"
                      size="small"
                      type="text"
                      variant="outlined"
                      label="Description"
                      placeholder="Description"
                      value={formik.values.description}
                      onChange={formik.handleChange}
                      error={Boolean(formik.errors.description)}
                      helperText={formik.errors.description}
                      multiline
                      rows={4}
                    />
                  </Grid>
                </Grid>
                <Grid
                  container
                  justifyContent="space-between"
                  spacing={1}
                  sx={{ mt: 2 }}
                >
                  <Grid item alignSelf="flex-start" sx={{ flexGrow: 1 }}>
                    <Grid item xs={12}>
                      Content
                    </Grid>
                    <ReactQuill
                      modules={richTextModules}
                      value={formik.values.content}
                      onChange={(e) => {
                        formik.setFieldValue('content', e);
                      }}
                      placeholder="Content"
                    />
                    {formik.touched.content && formik.errors.content && (
                      <FormHelperText error>
                        {formik.errors.content}
                      </FormHelperText>
                    )}
                  </Grid>
                </Grid>
                <Grid
                  container
                  justifyContent="space-between"
                  spacing={1}
                  sx={{ mt: 2 }}
                >
                  <Grid item alignSelf="flex-start" sx={{ flexGrow: 1 }}>
                    <Grid item xs={12}>
                      Cover
                    </Grid>
                    <Box>
                      <input
                        type="file"
                        onChange={({ target: { files } }) => {
                          formik.setFieldValue(
                            'cover',
                            files && files.length ? files[0] : null
                          );
                        }}
                        name="cover"
                      />
                    </Box>
                    {formik.touched.cover && formik.errors.cover && (
                      <FormHelperText error>
                        {formik.errors.cover}
                      </FormHelperText>
                    )}
                  </Grid>

                  <Grid item sx={{ flexGrow: 2 }}></Grid>
                </Grid>
              </Paper>
            </Grid>

            <Grid item xs={12} sm={3}>
              <Paper sx={{ p: 2 }}>
                <Grid container>
                  <Button
                    type="submit"
                    variant="contained"
                    disabled={submitting}
                    size="small"
                  >
                    {submitting ? 'Saving' : 'Save'}
                  </Button>
                </Grid>
              </Paper>
            </Grid>
          </Grid>
        </form>
      </Box>
    </>
  );
};

export default CreateSuccessStoryForm;
