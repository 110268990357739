import { Grid, Typography } from '@mui/material';
import { FormikHelpers } from 'formik';
import { useParams } from 'react-router-dom';
import { useState, useEffect } from 'react';
import Layout from '../../../core/ui/layout/Layout';
import { useSendApiData } from '../../../core/hooks/useSendApiData';
import { toastError, toastMessage } from '../../../core/utils/ui/alert';
import { parseValidationErrors } from '../../../core/utils/validation';
import useFetchApiData from '../../../core/hooks/useFetchApiData';
import { VacancyEdit, Vacancy } from '../vacancy';
import EditVacancyForm from '../components/EditVacancyForm';
import ReturnButton from '../../../core/ui/utility/ReturnButton';
import Loader from '../../../core/ui/utility/Loader';

const EditVacancyContainer = () => {
  const { id } = useParams();
  const { callApi, loading: submitting } = useSendApiData();
  const { fetchData, loading } = useFetchApiData();
  const [vacancy, setVacancy] = useState<Vacancy | null>(null);

  const fetchVacancy = () =>
    fetchData(`auth-ea35a/vacancies/${id}`, {
      onSuccess: (data: Vacancy) => {
        setVacancy(data);
      },
    });

  useEffect(() => {
    fetchVacancy();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmit = async (
    values: VacancyEdit,
    { setFieldError }: FormikHelpers<VacancyEdit>
  ) => {
    let success = false;

    const formData: any = { ...values };
    const dateFields: string[] = ['deadline'];
    dateFields.forEach((field) => {
      if (formData[field]) {
        formData[field] = formData[field]
          .toISOString()
          .substring(0, 10) as unknown as Date;
      }
    });

    await callApi({
      endpoint: `auth-ea35a/vacancies/${id}`,
      method: 'patch',
      data: formData,
      headers: {
        'Content-Type': 'application/json',
      },
      onValidationError: (err) => parseValidationErrors(err, setFieldError),
      onError: toastError,
      onSuccess: async (_) => {
        await fetchVacancy();
        toastMessage('Vacancy Edited');
        success = true;
      },
    });

    return success;
  };

  // if (loading || !vacancy) return <Loading />;

  return (
    <Layout renderLeftToolbar={() => <ReturnButton to="/vacancies" />}>
      <Loader loading={loading || !vacancy}>
        <Grid sx={{ p: 2 }}>
          <Grid container sx={{ mb: 1, px: 1 }}>
            <Typography variant="h5">Edit Vacancy</Typography>
          </Grid>
          <EditVacancyForm
            vacancy={vacancy!}
            onSubmit={handleSubmit}
            submitting={submitting}
          />
        </Grid>
      </Loader>
    </Layout>
  );
};

export default EditVacancyContainer;
