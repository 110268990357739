import { Box } from '@mui/material';
import { useState } from 'react';
import useFetchApiData from '../../../core/hooks/useFetchApiData';
import Layout from '../../../core/ui/layout/Layout';
import TableContainer from '../../../core/ui/table/TableContainer';
import { TableHeader } from '../../../core/ui/table/tableTypes';
import { Paginated } from '../../../core/utils/types';
import { parseQuery } from '../../../core/utils/utility';
import { Report } from '../report';
import { useSendApiData } from '../../../core/hooks/useSendApiData';
import { toastError, toastMessage } from '../../../core/utils/ui/alert';

const tableHeaders: TableHeader[] = [
  { field: 'title', label: 'Title', align: 'left' },
  { field: 'report_type', label: 'Report Type', align: 'left' },
];
const ReportList = () => {
  const [data, setData] = useState<Record<string, any>[]>([]);
  const { fetchData } = useFetchApiData();
  const { callApi } = useSendApiData();

  const handleFetchData = async (query: any, cb: (c: number) => void) => {
    await fetchData(`reports${parseQuery(query)}`, {
      onSuccess: ({ total, data: apiData }: Paginated<Report>) => {
        setData(apiData);
        cb(total);
      },
    });
  };

  const handleDelete = async (id: number | string, showMessage = true) => {
    let success = false;

    await callApi({
      endpoint: `auth-ea35a/reports/${id}`,
      data: {},
      method: 'delete',
      onSuccess: async () => {
        if (showMessage) toastMessage('Report Removed');
        success = true;
      },
      onError: () => {
        if (showMessage)
          toastError('Something went wrong while deleting. Try again');
      },
    });

    return success;
  };

  return (
    <>
      <Layout>
        <Box sx={{ p: 2 }}>
          <TableContainer
            modelToken="report"
            tableHeaders={tableHeaders}
            data={data}
            modelLabel="Reports"
            addRoute="/reports/create"
            editRoutePrefix="/reports/edit"
            actions={{
              onFetchData: handleFetchData,
              onDelete: handleDelete,
            }}
            fieldMap={{
              title: 'string',
              report_type: 'string',
            }}
            settings={{
              canCreate: true,
              canEdit: true,
              canDelete: true,
            }}
          />
        </Box>
      </Layout>
    </>
  );
};

export default ReportList;
