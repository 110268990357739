import { Box } from '@mui/material';
import { useState } from 'react';
import useFetchApiData from '../../../core/hooks/useFetchApiData';
import Layout from '../../../core/ui/layout/Layout';
import TableContainer from '../../../core/ui/table/TableContainer';
import { TableHeader } from '../../../core/ui/table/tableTypes';
import { Paginated } from '../../../core/utils/types';
import { parseQuery } from '../../../core/utils/utility';
import { Subscription } from '../subscription';

const tableHeaders: TableHeader[] = [
  { field: 'email', label: 'Email', align: 'left' },
];
const SubscriptionList = () => {
  const [data, setData] = useState<Record<string, any>[]>([]);
  const { fetchData } = useFetchApiData();

  const handleFetchData = async (query: any, cb: (c: number) => void) => {
    await fetchData(`auth-ea35a/subscriptions${parseQuery(query)}`, {
      onSuccess: ({ total, data: apiData }: Paginated<Subscription>) => {
        setData(apiData);
        cb(total);
      },
    });
  };

  return (
    <>
      <Layout>
        <Box sx={{ p: 2 }}>
          <TableContainer
            modelToken="subscription"
            tableHeaders={tableHeaders}
            data={data}
            modelLabel="Subscriptions"
            addRoute="/subscriptions/create"
            editRoutePrefix="/subscriptions/edit"
            actions={{
              onFetchData: handleFetchData,
            }}
            fieldMap={{
              email: 'string',
            }}
            settings={{
              canCreate: false,
              canEdit: false,
              canDelete: false,
            }}
          />
        </Box>
      </Layout>
    </>
  );
};

export default SubscriptionList;
