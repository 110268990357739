import { Grid, Typography } from '@mui/material';
import { FormikHelpers } from 'formik';
import { useParams } from 'react-router-dom';
import { useState, useEffect } from 'react';
import Layout from '../../../core/ui/layout/Layout';
import { useSendApiData } from '../../../core/hooks/useSendApiData';
import { toastError, toastMessage } from '../../../core/utils/ui/alert';
import { parseValidationErrors } from '../../../core/utils/validation';
import useFetchApiData from '../../../core/hooks/useFetchApiData';
import { ContactEdit, Contact } from '../contact';
import EditContactForm from '../components/ViewContactForm';
import ReturnButton from '../../../core/ui/utility/ReturnButton';
import Loader from '../../../core/ui/utility/Loader';

const EditContactContainer = () => {
  const { id } = useParams();
  const { callApi, loading: submitting } = useSendApiData();
  const { fetchData, loading } = useFetchApiData();
  const [contact, setContact] = useState<Contact | null>(null);

  const fetchContact = () =>
    fetchData(`auth-ea35a/contacts/${id}`, {
      onSuccess: (data: Contact) => {
        setContact(data);
      },
    });

  useEffect(() => {
    fetchContact();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmit = async (
    values: ContactEdit,
    { setFieldError }: FormikHelpers<ContactEdit>
  ) => {
    let success = false;

    const formData: any = { ...values };
    const dateFields: string[] = [];
    dateFields.forEach((field) => {
      if (formData[field]) {
        formData[field] = formData[field]
          .toISOString()
          .substring(0, 10) as unknown as Date;
      }
    });

    await callApi({
      endpoint: `auth-ea35a/contacts/${id}`,
      method: 'patch',
      data: formData,
      headers: {
        'Content-Type': 'application/json',
      },
      onValidationError: (err) => parseValidationErrors(err, setFieldError),
      onError: toastError,
      onSuccess: async (_) => {
        await fetchContact();
        toastMessage('Contact Edited');
        success = true;
      },
    });

    return success;
  };

  return (
    <Layout renderLeftToolbar={() => <ReturnButton to="/contacts" />}>
      <Loader loading={loading || !contact}>
        <Grid sx={{ p: 2 }}>
          <Grid container sx={{ mb: 1, px: 1 }}>
            <Typography variant="h5">View Contact</Typography>
          </Grid>
          <EditContactForm
            contact={contact!}
            onSubmit={handleSubmit}
            submitting={submitting}
          />
        </Grid>
      </Loader>
    </Layout>
  );
};

export default EditContactContainer;
