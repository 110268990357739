// import { FilterList } from '@mui/icons-material';
import { Box, Grid } from '@mui/material';
import { useState } from 'react';
import Filter from './Filter';
import { TableHeader } from '../../tableTypes';
import TableSettings from '../TableSettings';
import { FieldType } from './filterTypes';

type FilterBlockProps = {
  modelName: string;
  tableHeaders: TableHeader[];
  fieldVisible: Record<string, boolean>;
  toggleFieldVisibility: (f: string) => void;
  tableDense: boolean;
  toggleTableDensity: () => void;
  onFilterApply: (filter: any) => void;
  fieldMap: Record<string, FieldType>;
};

const FilterBlock = ({
  modelName,
  tableHeaders,
  fieldVisible,
  toggleFieldVisibility,
  tableDense,
  toggleTableDensity,
  onFilterApply,
  fieldMap,
}: FilterBlockProps) => {
  const [showFilter, setShowFilter] = useState(false);

  return (
    <>
      <Box
        sx={{ mb: '4px', mt: 2 }}
        display="flex"
        justifyContent="space-between"
        alignItems="flex-end"
      >
        {/* {!showFilter ? <></> : } */}
        <Box></Box>
        <Grid item>
          <TableSettings
            tableHeaders={tableHeaders}
            fieldVisible={fieldVisible}
            toggleFieldVisibility={toggleFieldVisibility}
            tableDense={tableDense}
            toggleTableDensity={toggleTableDensity}
          />
        </Grid>
      </Box>

      {showFilter && (
        <Filter
          label={modelName}
          fieldMap={fieldMap}
          onClear={() => {
            setShowFilter(false);
            onFilterApply({});
          }}
          onApply={onFilterApply}
        />
      )}
    </>
  );
};

export default FilterBlock;
