import { Grid, Typography } from '@mui/material';
import { FormikHelpers } from 'formik';
import { useParams } from 'react-router-dom';
import { useState, useEffect } from 'react';
import Layout from '../../../core/ui/layout/Layout';
import { useSendApiData } from '../../../core/hooks/useSendApiData';
import { toastError, toastMessage } from '../../../core/utils/ui/alert';
import { parseValidationErrors } from '../../../core/utils/validation';
import useFetchApiData from '../../../core/hooks/useFetchApiData';
import { SuccessStoryEdit, SuccessStory } from '../successStory';
import EditSuccessStoryForm from '../components/EditSuccessStoryForm';
import ReturnButton from '../../../core/ui/utility/ReturnButton';
import Loader from '../../../core/ui/utility/Loader';
import { parseFormQuery } from '../../../core/utils/utility';

const EditSuccessStoryContainer = () => {
  const { id } = useParams();
  const { callApi, loading: submitting } = useSendApiData();
  const { fetchData, loading } = useFetchApiData();
  const [successStory, setSuccessStory] = useState<SuccessStory | null>(null);

  const fetchSuccessStory = () =>
    fetchData(`success-stories/${id}`, {
      onSuccess: (data: SuccessStory) => {
        setSuccessStory(data);
      },
    });

  useEffect(() => {
    fetchSuccessStory();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmit = async (
    values: SuccessStoryEdit,
    { setFieldError }: FormikHelpers<SuccessStoryEdit>
  ) => {
    let success = false;

    const formData: any = parseFormQuery(values);

    await callApi({
      endpoint: `auth-ea35a/success-stories/${id}?_method=PATCH`,
      method: 'post',
      data: formData,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      onValidationError: (err) => parseValidationErrors(err, setFieldError),
      onError: toastError,
      onSuccess: async (_) => {
        await fetchSuccessStory();
        toastMessage('Success Story Edited');
        success = true;
      },
    });

    return success;
  };

  return (
    <Layout renderLeftToolbar={() => <ReturnButton to="/success-stories" />}>
      <Loader loading={loading || !successStory}>
        <Grid sx={{ p: 2 }}>
          <Grid container sx={{ mb: 1, px: 1 }}>
            <Typography variant="h5">Edit SuccessStory</Typography>
          </Grid>
          <EditSuccessStoryForm
            successStory={successStory!}
            onSubmit={handleSubmit}
            submitting={submitting}
          />
        </Grid>
      </Loader>
    </Layout>
  );
};

export default EditSuccessStoryContainer;
