import { Grid, Typography } from '@mui/material';
import { FormikHelpers } from 'formik';
import Layout from '../../../core/ui/layout/Layout';
import { useSendApiData } from '../../../core/hooks/useSendApiData';
import { toastError, toastMessage } from '../../../core/utils/ui/alert';
import { parseValidationErrors } from '../../../core/utils/validation';
import { ImpactCreate } from '../impact';
import CreateImpactForm from '../components/CreateImpactForm';
import ReturnButton from '../../../core/ui/utility/ReturnButton';
import { parseFormQuery } from '../../../core/utils/utility';

const CreateImpactContainer = () => {
  const { callApi, loading: submitting } = useSendApiData();

  const handleSubmit = async (
    values: ImpactCreate,
    { setFieldError }: FormikHelpers<ImpactCreate>
  ) => {
    let success = false;

    const formData: any = parseFormQuery(
      values,
      ['from_date', 'to_date'],
      true
    );

    await callApi({
      endpoint: 'auth-ea35a/impacts',
      data: formData,
      headers: {
        'Content-Type': 'application/json',
      },
      onValidationError: (err) => parseValidationErrors(err, setFieldError),
      onError: toastError,
      onSuccess: async (_) => {
        toastMessage('Impact Created');
        success = true;
      },
    });

    return success;
  };

  return (
    <Layout renderLeftToolbar={() => <ReturnButton to="/impacts" />}>
      <Grid sx={{ p: 2 }}>
        <Grid container sx={{ mb: 2, px: 1 }}>
          <Typography variant="h5">Add Impact</Typography>
        </Grid>
        <CreateImpactForm onSubmit={handleSubmit} submitting={submitting} />
      </Grid>
    </Layout>
  );
};

export default CreateImpactContainer;
