import { FormikHelpers, useFormik } from 'formik';
import { PartnerEdit, Partner } from '../partner';
import { Grid, TextField, Box, Paper } from '@mui/material';
import FileContainer from '../../../core/ui/utility/FileContainer';

type PartnerProps = {
  onSubmit: (
    value: PartnerEdit,
    helpers: FormikHelpers<PartnerEdit>
  ) => Promise<boolean>;
  submitting: boolean;
  partner: Partner;
};

const EditPartnerForm = ({ onSubmit, submitting, partner }: PartnerProps) => {
  const handleSubmit = async (
    value: PartnerEdit,
    helpers: FormikHelpers<PartnerEdit>
  ) => {
    const success = await onSubmit(value, helpers);
    if (success) formik.resetForm();
  };

  const initialValues: PartnerEdit = {
    company_name: partner.company_name,
    email: partner.email,
    phone: partner.phone,
    attachment: null,
  };

  const formik = useFormik({
    initialValues,
    onSubmit: handleSubmit,
  });

  // console.log('Edit Form', initialValues);

  return (
    <>
      <Box sx={{ flexGrow: 1 }}>
        <form onSubmit={formik.handleSubmit}>
          <Grid container spacing={1}>
            <Grid item xs={12} sm={9}>
              <Paper sx={{ p: 2, pb: 3 }}>
                <Grid
                  container
                  justifyContent="space-between"
                  spacing={1}
                  sx={{ mt: 2 }}
                >
                  <Grid item alignSelf="flex-start" sx={{ flexGrow: 1 }}>
                    <TextField
                      fullWidth
                      name="company_name"
                      size="small"
                      type="text"
                      variant="outlined"
                      label="Company Name"
                      placeholder="Company Name"
                      value={formik.values.company_name}
                      onChange={formik.handleChange}
                      error={Boolean(formik.errors.company_name)}
                      helperText={formik.errors.company_name}
                    />
                  </Grid>

                  <Grid item alignSelf="flex-start" sx={{ flexGrow: 1 }}>
                    <TextField
                      fullWidth
                      name="email"
                      size="small"
                      type="text"
                      variant="outlined"
                      label="Email"
                      placeholder="Email"
                      value={formik.values.email}
                      onChange={formik.handleChange}
                      error={Boolean(formik.errors.email)}
                      helperText={formik.errors.email}
                    />
                  </Grid>

                  <Grid item alignSelf="flex-start" sx={{ flexGrow: 1 }}>
                    <TextField
                      fullWidth
                      name="phone"
                      size="small"
                      type="text"
                      variant="outlined"
                      label="Phone"
                      placeholder="Phone"
                      value={formik.values.phone}
                      onChange={formik.handleChange}
                      error={Boolean(formik.errors.phone)}
                      helperText={formik.errors.phone}
                    />
                  </Grid>
                </Grid>
                <Grid
                  container
                  justifyContent="space-between"
                  spacing={1}
                  sx={{ mt: 2 }}
                >
                  <Grid item alignSelf="flex-start" sx={{ flexGrow: 1 }}>
                    <Grid item xs={12}>
                      Attachment
                    </Grid>
                    <FileContainer media={partner.media[0]} />
                  </Grid>

                  <Grid item sx={{ flexGrow: 2 }}></Grid>
                </Grid>
              </Paper>
            </Grid>

            <Grid item xs={12} sm={3}></Grid>
          </Grid>
        </form>
      </Box>
    </>
  );
};

export default EditPartnerForm;
