import { Grid, Typography } from '@mui/material';
import { FormikHelpers } from 'formik';
import { useParams } from 'react-router-dom';
import { useState, useEffect } from 'react';
import Layout from '../../../core/ui/layout/Layout';
import { useSendApiData } from '../../../core/hooks/useSendApiData';
import { toastError, toastMessage } from '../../../core/utils/ui/alert';
import { parseValidationErrors } from '../../../core/utils/validation';
import useFetchApiData from '../../../core/hooks/useFetchApiData';
import { PublicationEdit, Publication } from '../publication';
import EditPublicationForm from '../components/EditPublicationForm';
import ReturnButton from '../../../core/ui/utility/ReturnButton';
import Loader from '../../../core/ui/utility/Loader';

const EditPublicationContainer = () => {
  const { id } = useParams();
  const { callApi, loading: submitting } = useSendApiData();
  const { fetchData, loading } = useFetchApiData();
  const [publication, setPublication] = useState<Publication | null>(null);

  const fetchPublication = () =>
    fetchData(`publications/${id}`, {
      onSuccess: (data: Publication) => {
        setPublication(data);
      },
    });

  useEffect(() => {
    fetchPublication();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmit = async (
    values: PublicationEdit,
    { setFieldError }: FormikHelpers<PublicationEdit>
  ) => {
    let success = false;

    const formData: any = new FormData();
    Object.entries(values).forEach(([key, val]) => {
      if (val !== null) {
        formData.append(key, val as Blob);
      }
    });

    await callApi({
      endpoint: `auth-ea35a/publications/${id}?_method=PATCH`,
      method: 'post',
      data: formData,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      onValidationError: (err) => parseValidationErrors(err, setFieldError),
      onError: toastError,
      onSuccess: async (_) => {
        await fetchPublication();
        toastMessage('Publication Edited');
        success = true;
      },
    });

    return success;
  };

  return (
    <Layout renderLeftToolbar={() => <ReturnButton to="/publications" />}>
      <Loader loading={loading || !publication}>
        <Grid sx={{ p: 2 }}>
          <Grid container sx={{ mb: 1, px: 1 }}>
            <Typography variant="h5">Edit Publication</Typography>
          </Grid>
          <EditPublicationForm
            publication={publication!}
            onSubmit={handleSubmit}
            submitting={submitting}
          />
        </Grid>
      </Loader>
    </Layout>
  );
};

export default EditPublicationContainer;
