import { Grid, Typography } from "@mui/material";
import { FormikHelpers } from "formik";
import Layout from "../../../core/ui/layout/Layout";
import { useSendApiData } from "../../../core/hooks/useSendApiData";
import { toastError, toastMessage } from "../../../core/utils/ui/alert";
import { parseValidationErrors } from "../../../core/utils/validation";
import { BoardCreate } from "../board";
import CreateBoardForm from "../components/CreateBoardForm";
import ReturnButton from "../../../core/ui/utility/ReturnButton";

const CreateBoardContainer = () => {
  const { callApi, loading: submitting } = useSendApiData();
  

  const handleSubmit = async (
    values: BoardCreate,
    { setFieldError }: FormikHelpers<BoardCreate>
  ) => {
    let success = false;

    const formData: any = new FormData();
    Object.entries(values).forEach(([key, val]) => {
      formData.append(key, val as Blob);
    });

    await callApi({
      endpoint: 'auth-ea35a/boards',
      data: formData,
      headers: {
        'Content-Type': 'multipart/form-data'
      },
      onValidationError: (err) => parseValidationErrors(err, setFieldError),
      onError: toastError,
      onSuccess: async (_) => {
        toastMessage('Board Created');
        success = true;
      },
    });

    return success;
  };
  

  return (
    <Layout renderLeftToolbar={() => <ReturnButton to="/boards" />}>
      <Grid sx={{ p: 2 }}>
        <Grid container sx={{ mb: 2, px: 1 }}>
          <Typography variant="h5">Add Board</Typography>
        </Grid>
        <CreateBoardForm  onSubmit={handleSubmit} submitting={submitting} />
      </Grid>
    </Layout>
  );
};

export default CreateBoardContainer;
