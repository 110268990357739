import { FormikHelpers, useFormik } from 'formik';
import { PublicationCreate } from '../publication';
import {
  Grid,
  TextField,
  Box,
  FormHelperText,
  Paper,
  Button,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';

type PublicationProps = {
  onSubmit: (
    value: PublicationCreate,
    helpers: FormikHelpers<PublicationCreate>
  ) => Promise<boolean>;
  submitting: boolean;
};

const CreatePublicationForm = ({ onSubmit, submitting }: PublicationProps) => {
  const nav = useNavigate();

  const handleSubmit = async (
    value: PublicationCreate,
    helpers: FormikHelpers<PublicationCreate>
  ) => {
    const success = await onSubmit(value, helpers);
    if (success) {
      nav('/', { replace: true });
      nav('/publications/create', { replace: true });
    }
  };

  const initialValues: PublicationCreate = { title: '', attachment: null };

  const formik = useFormik({
    initialValues,
    onSubmit: handleSubmit,
  });

  // console.log('Edit Form', initialValues);

  return (
    <>
      <Box sx={{ flexGrow: 1 }}>
        <form onSubmit={formik.handleSubmit}>
          <Grid container spacing={1}>
            <Grid item xs={12} sm={9}>
              <Paper sx={{ p: 2, pb: 3 }}>
                <Grid
                  container
                  justifyContent="space-between"
                  spacing={1}
                  sx={{ mt: 2 }}
                >
                  <Grid item alignSelf="flex-start" sx={{ flexGrow: 1 }}>
                    <TextField
                      fullWidth
                      name="title"
                      size="small"
                      type="text"
                      variant="outlined"
                      label="Title"
                      placeholder="Title"
                      value={formik.values.title}
                      onChange={formik.handleChange}
                      error={Boolean(formik.errors.title)}
                      helperText={formik.errors.title}
                    />
                  </Grid>

                  <Grid item sx={{ flexGrow: 1 }}></Grid>
                </Grid>
                <Grid
                  container
                  justifyContent="space-between"
                  spacing={1}
                  sx={{ mt: 2 }}
                >
                  <Grid item alignSelf="flex-start" sx={{ flexGrow: 1 }}>
                    <Grid item xs={12}>
                      Attachment
                    </Grid>
                    <Box>
                      <input
                        type="file"
                        onChange={({ target: { files } }) => {
                          formik.setFieldValue(
                            'attachment',
                            files && files.length ? files[0] : null
                          );
                        }}
                        name="attachment"
                      />
                    </Box>
                    {formik.touched.attachment && formik.errors.attachment && (
                      <FormHelperText error>
                        {formik.errors.attachment}
                      </FormHelperText>
                    )}
                  </Grid>

                  <Grid item sx={{ flexGrow: 2 }}></Grid>
                </Grid>
              </Paper>
            </Grid>

            <Grid item xs={12} sm={3}>
              <Paper sx={{ p: 2 }}>
                <Grid container>
                  <Button
                    type="submit"
                    variant="contained"
                    disabled={submitting}
                    size="small"
                  >
                    {submitting ? 'Saving' : 'Save'}
                  </Button>
                </Grid>
              </Paper>
            </Grid>
          </Grid>
        </form>
      </Box>
    </>
  );
};

export default CreatePublicationForm;
