import {
  CheckCircle,
  Feed,
  Group,
  Groups,
  GroupWork,
  Info,
  MailOutline,
  Message,
  Newspaper,
  Summarize,
  TrendingUp,
  Work,
} from '@mui/icons-material';
import { Divider, Grid, List, Toolbar, Typography } from '@mui/material';
import { useLocation } from 'react-router-dom';
import SidebarLink from './SidebarLink';

const Sidebar = () => {
  const location = useLocation();

  return (
    <>
      <div>
        <Toolbar variant="dense" />
        <Divider />
        <List sx={{ p: 1 }}>
          <Grid
            container
            flexDirection="column"
            justifyContent="space-between"
            sx={{}}
          >
            <Grid item>
              <Typography variant="subtitle1" fontSize="12px" fontWeight="bold">
                COLLECTION TYPES
              </Typography>
              {/* <SidebarLink
                to="/"
                label="Home"
                active={location.pathname === '/'}
                icon={<Home />}
              /> */}
              <SidebarLink
                to="/boards"
                label="Board"
                active={location.pathname.startsWith('/boards')}
                icon={<Group />}
              />
              <SidebarLink
                to="/contacts"
                label="Contact"
                active={location.pathname.startsWith('/contacts')}
                icon={<Message />}
              />
              <SidebarLink
                to="/impacts"
                label="Impact"
                active={location.pathname.startsWith('/impacts')}
                icon={<TrendingUp />}
              />
              <SidebarLink
                to="/newsletters"
                label="Newsletter"
                active={location.pathname.startsWith('/newsletters')}
                icon={<Newspaper />}
              />
              <SidebarLink
                to="/partners"
                label="Partner"
                active={location.pathname.startsWith('/partners')}
                icon={<GroupWork />}
              />
              <SidebarLink
                to="/publications"
                label="Publication"
                active={location.pathname.startsWith('/publications')}
                icon={<Feed />}
              />
              <SidebarLink
                to="/reports"
                label="Report"
                active={location.pathname.startsWith('/reports')}
                icon={<Summarize />}
              />
              <SidebarLink
                to="/subscriptions"
                label="Subscription"
                active={location.pathname.startsWith('/subscriptions')}
                icon={<MailOutline />}
              />
              <SidebarLink
                to="/success-stories"
                label="Success Story"
                active={location.pathname.startsWith('/success-stories')}
                icon={<CheckCircle />}
              />
              <SidebarLink
                to="/teams"
                label="Team"
                active={location.pathname.startsWith('/teams')}
                icon={<Groups />}
              />
              <SidebarLink
                to="/vacancies"
                label="Vacancy"
                active={location.pathname.startsWith('/vacancies')}
                icon={<Work />}
              />
            </Grid>

            <Grid item>
              <Typography
                variant="subtitle1"
                fontSize="12px"
                fontWeight="bold"
                sx={{ mt: 2 }}
              >
                SINGLE TYPES
              </Typography>
              <SidebarLink
                to="/home-info"
                label="Home Info"
                active={location.pathname.startsWith('/home-info')}
                icon={<Info />}
              />
            </Grid>
          </Grid>
        </List>
      </div>
    </>
  );
};

export default Sidebar;
