import { Grid, Typography } from '@mui/material';
import { FormikHelpers } from 'formik';
import { useParams } from 'react-router-dom';
import { useState, useEffect } from 'react';
import Layout from '../../../core/ui/layout/Layout';
import { useSendApiData } from '../../../core/hooks/useSendApiData';
import { toastError, toastMessage } from '../../../core/utils/ui/alert';
import { parseValidationErrors } from '../../../core/utils/validation';
import useFetchApiData from '../../../core/hooks/useFetchApiData';
import { ImpactEdit, Impact } from '../impact';
import EditImpactForm from '../components/EditImpactForm';
import ReturnButton from '../../../core/ui/utility/ReturnButton';
import Loader from '../../../core/ui/utility/Loader';
import { parseFormQuery } from '../../../core/utils/utility';

const EditImpactContainer = () => {
  const { id } = useParams();
  const { callApi, loading: submitting } = useSendApiData();
  const { fetchData, loading } = useFetchApiData();
  const [impact, setImpact] = useState<Impact | null>(null);

  const fetchImpact = () =>
    fetchData(`impacts/${id}`, {
      onSuccess: (data: Impact) => {
        setImpact(data);
      },
    });

  useEffect(() => {
    fetchImpact();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmit = async (
    values: ImpactEdit,
    { setFieldError }: FormikHelpers<ImpactEdit>
  ) => {
    let success = false;

    const formData: any = parseFormQuery(
      values,
      ['from_date', 'to_date'],
      true
    );

    await callApi({
      endpoint: `auth-ea35a/impacts/${id}`,
      method: 'patch',
      data: formData,
      headers: {
        'Content-Type': 'application/json',
      },
      onValidationError: (err) => parseValidationErrors(err, setFieldError),
      onError: toastError,
      onSuccess: async (_) => {
        await fetchImpact();
        toastMessage('Impact Edited');
        success = true;
      },
    });

    return success;
  };

  return (
    <Layout renderLeftToolbar={() => <ReturnButton to="/impacts" />}>
      <Loader loading={loading || !impact}>
        <Grid sx={{ p: 2 }}>
          <Grid container sx={{ mb: 1, px: 1 }}>
            <Typography variant="h5">Edit Impact</Typography>
          </Grid>
          <EditImpactForm
            impact={impact!}
            onSubmit={handleSubmit}
            submitting={submitting}
          />
        </Grid>
      </Loader>
    </Layout>
  );
};

export default EditImpactContainer;
