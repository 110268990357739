import { FormikHelpers, useFormik } from 'formik';
import { ContactEdit, Contact } from '../contact';
import { Grid, TextField, Paper, Box } from '@mui/material';

type ContactProps = {
  onSubmit: (
    value: ContactEdit,
    helpers: FormikHelpers<ContactEdit>
  ) => Promise<boolean>;
  submitting: boolean;
  contact: Contact;
};

const EditContactForm = ({ onSubmit, submitting, contact }: ContactProps) => {
  const handleSubmit = async (
    value: ContactEdit,
    helpers: FormikHelpers<ContactEdit>
  ) => {
    const success = await onSubmit(value, helpers);
    if (success) formik.resetForm();
  };

  const initialValues: ContactEdit = {
    name: contact.name,
    email: contact.email,
    subject: contact.subject,
    message: contact.message,
  };

  const formik = useFormik({
    initialValues,
    onSubmit: handleSubmit,
  });

  // console.log('Edit Form', initialValues);

  return (
    <>
      <Box sx={{ flexGrow: 1 }}>
        <form onSubmit={formik.handleSubmit}>
          <Grid container spacing={1}>
            <Grid item xs={12} sm={9}>
              <Paper sx={{ p: 2, pb: 3 }}>
                <Grid
                  container
                  justifyContent="space-between"
                  spacing={1}
                  sx={{ mt: 2 }}
                >
                  <Grid item alignSelf="flex-start" sx={{ flexGrow: 1 }}>
                    <TextField
                      fullWidth
                      name="name"
                      size="small"
                      type="text"
                      variant="outlined"
                      label="Name"
                      placeholder="Name"
                      value={formik.values.name}
                      onChange={formik.handleChange}
                      error={Boolean(formik.errors.name)}
                      helperText={formik.errors.name}
                    />
                  </Grid>

                  <Grid item alignSelf="flex-start" sx={{ flexGrow: 1 }}>
                    <TextField
                      fullWidth
                      name="email"
                      size="small"
                      type="text"
                      variant="outlined"
                      label="Email"
                      placeholder="Email"
                      value={formik.values.email}
                      onChange={formik.handleChange}
                      error={Boolean(formik.errors.email)}
                      helperText={formik.errors.email}
                    />
                  </Grid>
                </Grid>
                <Grid
                  container
                  justifyContent="space-between"
                  spacing={1}
                  sx={{ mt: 2 }}
                >
                  <Grid item alignSelf="flex-start" sx={{ flexGrow: 1 }}>
                    <TextField
                      fullWidth
                      name="subject"
                      size="small"
                      type="text"
                      variant="outlined"
                      label="Subject"
                      placeholder="Subject"
                      value={formik.values.subject}
                      onChange={formik.handleChange}
                      error={Boolean(formik.errors.subject)}
                      helperText={formik.errors.subject}
                    />
                  </Grid>
                </Grid>
                <Grid
                  container
                  justifyContent="space-between"
                  spacing={1}
                  sx={{ mt: 2 }}
                >
                  <Grid item alignSelf="flex-start" sx={{ flexGrow: 1 }}>
                    <TextField
                      fullWidth
                      name="message"
                      size="small"
                      type="text"
                      variant="outlined"
                      label="Message"
                      placeholder="Message"
                      value={formik.values.message}
                      onChange={formik.handleChange}
                      error={Boolean(formik.errors.message)}
                      helperText={formik.errors.message}
                      multiline
                      rows={4}
                    />
                  </Grid>
                </Grid>
              </Paper>
            </Grid>

            <Grid item xs={12} sm={3}>
              {/* <Paper sx={{ p: 2 }}></Paper> */}
            </Grid>
          </Grid>
        </form>
      </Box>
    </>
  );
};

export default EditContactForm;
