import { Box } from '@mui/material';
import { useState } from 'react';
import useFetchApiData from '../../../core/hooks/useFetchApiData';
import TableContainer from '../../../core/ui/table/TableContainer';
import { TableHeader } from '../../../core/ui/table/tableTypes';
import { Paginated } from '../../../core/utils/types';
import { parseQuery } from '../../../core/utils/utility';
import { Vacancy } from '../vacancy';
import { useSendApiData } from '../../../core/hooks/useSendApiData';
import { toastError, toastMessage } from '../../../core/utils/ui/alert';

type JobListProps = {
  vacancyId: number;
};

const tableHeaders: TableHeader[] = [
  { field: 'first_name', label: 'First Name', align: 'left' },
  { field: 'last_name', label: 'Last Name', align: 'left' },
  { field: 'email', label: 'Email', align: 'left' },
  { field: 'phone', label: 'Phone', align: 'left' },
];

const JobList = ({ vacancyId }: JobListProps) => {
  const [data, setData] = useState<Record<string, any>[]>([]);
  const { fetchData } = useFetchApiData();
  const { callApi } = useSendApiData();

  const handleFetchData = async (query: any, cb: (c: number) => void) => {
    await fetchData(
      `auth-ea35a/jobs/vacancy/${vacancyId}${parseQuery(query)}`,
      {
        onSuccess: ({ total, data: apiData }: Paginated<Vacancy>) => {
          setData(apiData);
          cb(total);
        },
      }
    );
  };

  const handleDelete = async (id: number | string, showMessage = true) => {
    let success = false;

    await callApi({
      endpoint: `auth-ea35a/jobs/${id}`,
      data: {},
      method: 'delete',
      onSuccess: async () => {
        if (showMessage) toastMessage('Vacancy Removed');
        success = true;
      },
      onError: () => {
        if (showMessage)
          toastError('Something went wrong while deleting. Try again');
      },
    });

    return success;
  };

  return (
    <>
      <Box sx={{ mt: 3 }}>
        <TableContainer
          modelToken="job"
          tableHeaders={tableHeaders}
          data={data}
          modelLabel="Job Applications"
          addRoute=""
          editRoutePrefix=""
          viewRoutePrefix={`/vacancies/${vacancyId}/jobs`}
          actions={{
            onFetchData: handleFetchData,
            onDelete: handleDelete,
          }}
          fieldMap={{
            first_name: 'string',
            last_name: 'string',
            email: 'string',
            phone: 'string',
          }}
          settings={{
            canCreate: false,
            canEdit: false,
            canDelete: true,
            canViewItem: true,
          }}
        />
      </Box>
    </>
  );
};

export default JobList;
